<template>
  <section>
    <div v-if="!isLoaded" class="h-screen vld-parent">
      <loading :active="!isLoaded" :is-full-page="false" />
    </div>
    <!-- Operator General  Settings -->

    <div class="mx-auto mt-3 lg:mt-10 md:mt-5 max-w-6xl" v-if="isLoaded">
      <div>
        <div class="font-bold text-22px text-oCharcoal">
          <i class="fas fa-id-badge text-22px text-oPurple"></i>
          <span class="pl-3">Operator General Settings</span>
        </div>
        <p class="break-words title-description mt-1">
          Operator App is used to pick up and drop off vehicles for rebalancing,
          charging, maintenance and swipe battery
        </p>
      </div>
      <div :key="`operatorGeneral_${counter}`" class=" mt-3 pt-5 border-t-2">
        <template v-for="(item, itemIndex) in getOperatorGeneralSettings">
          <SmartAppInput
            :key="itemIndex"
            :rowId="`operatorGeneral_${itemIndex}`"
            :type="item.type"
            :label="item.label"
            :placeholder="item.placeholder"
            :description="item.description"
            :isEdit="item.isEdit"
            :options="item.options"
            v-model="general[`${item.variable}`]"
            @save="onSave('general', `${item.variable}`, $event)"
          />
        </template>
      </div>

      <!-- Operator Task Wage Settings -->
      <div>
        <div class="font-bold text-22px text-oCharcoal">
          <i class="fas fa-money-bill text-22px text-oPurple"></i>
          <span class="pl-3">Operator Task Wage</span>
        </div>
        <p class="break-words title-description mt-1">
          If you have independent operator and you want to count their
          KPI/Earning by Value then you can define value for each task.
          Depending on their task you can sum up total numbers and calculate as
          Earning or KPI points
        </p>
      </div>
      <div :key="`operatorWage_${counter}`" class=" mt-3 pt-5 border-t-2">
        <template v-for="(item, itemIndex) in operatorWageSettings">
          <SmartAppInput
            v-if="itemIndex <= 1"
            :key="itemIndex"
            :rowId="`operatorWage_${itemIndex}`"
            :type="item.type"
            :label="item.label"
            :placeholder="item.placeholder"
            :description="item.description"
            :isEdit="item.isEdit"
            v-model="wage.charging[`${item.variable}`]"
            @save="onSave('charging', `${item.variable}`, $event)"
          />
          <SmartAppInput
            v-else
            :key="itemIndex"
            :rowId="`operatorWage_${itemIndex}`"
            :type="item.type"
            :label="item.label"
            :placeholder="item.placeholder"
            :description="item.description"
            :isEdit="item.isEdit"
            v-model="wage.rebalancing[`${item.variable}`]"
            @save="onSave('rebalancing', `${item.variable}`, $event)"
          />
        </template>
      </div>
    </div>
  </section>
</template>

<script>
import SmartAppInput from '@/components/form/SmartAppInput.vue'
import { EventBus } from '@/utils'
import { OperatorSettingsConfig } from '@/config/SettingsConfig'

export default {
  name: 'OperatorSettings',
  components: {
    SmartAppInput,
  },
  data() {
    return {
      isLoaded: false,
      counter: 0,
      currentSettingsGroup: null,
      general: {
        drop_off_photo_required: true,
        check_inside_drop_off_area: true,
        is_reservation_required: true,
        release_spot_reservation_time_in_min: 0,
      },

      wage: {
        charging: {
          rate: '',
          point: '',
        },
        rebalancing: {
          rate: '',
          point: '',
        },
      },
      operatorGeneralSettings: [
        {
          type: 'richselect',
          label: 'Drop-off Photo Required',
          description: `Choose YES if you want Operator to upload photo before they drop off vehicles to certain areas`,
          options: [
            { value: false, text: 'No' },
            { value: true, text: 'Yes' },
          ],
          variable: 'drop_off_photo_required',
          isEdit: false,
        },
        {
          type: 'richselect',
          label: 'Check Inside Drop-off Area',
          description: `Choose YES if you want system to Check operator dropped at legal place`,
          options: [
            { value: false, text: 'No' },
            { value: true, text: 'Yes' },
          ],
          variable: 'check_inside_drop_off_area',
          isEdit: false,
        },
        {
          type: 'richselect',
          label: 'Reservation Required',
          description: `Choose YES if you want an operator to reserve the release spot first for 15 mins then drop off vehicles. Selecting NO will let operators drop off vehicles without reserving a release spot.`,
          options: [
            { value: false, text: 'No' },
            { value: true, text: 'Yes' },
          ],
          variable: 'is_reservation_required',
          isEdit: false,
        },
        {
          type: 'number',
          label: 'Release Spot Reservation Timeout (Minutes)',
          placeholder: `e.g 5`,
          description:
            'If Reservation of parking spot before Release is enabled by system then operator need to have Reservation Timeout time defined which will define how long the reserved spot will be reserved. If you set value 15Mins it mean, operator can reserve a parking spot for 15 mins. After 15 mins the spot will automatically get released by system.',
          variable: 'release_spot_reservation_time_in_min',
          isEdit: false,
        },
      ],
      operatorWageSettings: [
        {
          type: 'number',
          label: 'Earning Rate (Charging)',
          placeholder: `e.g 5`,
          description:
            'If you want to measure by Earning for Charging Task then you can define number for each charging task. e.g If you set value 2 it mean, operator will earn 2 for each charging task.',
          variable: 'rate',
          isEdit: false,
        },
        {
          type: 'number',
          label: 'Point (Charging)',
          placeholder: `e.g 5`,
          description:
            'If you want to measure by Point for Charging Task then you can define number for each charging task. e.g If you set value 3  it mean, operator will earn 3 points for each charging task.',
          variable: 'point',
          isEdit: false,
        },
        {
          type: 'number',
          label: 'Earning Rate (Rebalance)',
          placeholder: `e.g 5`,
          description:
            'If you want to measure by Earning for Rebalancing Task then you can define number for each Rebalancing task. e.g If you set value 3  it mean, operator will earn 3  for each Rebalancing task.',
          variable: 'rate',
          isEdit: false,
        },
        {
          type: 'number',
          label: 'Point (Rebalance)',
          placeholder: `e.g 5`,
          description:
            'If you want to measure by Point for Rebalancing Task then you can define number for each Rebalancing task. e.g If you set value 5  it mean, operator will earn 5 points  for each Rebalancing task',
          variable: 'point',
          isEdit: false,
        },
      ],
    }
  },
  computed: {
    orgId() {
      return this.$store.getters['auth/organizationInfo'].id || null
    },
    getOperatorGeneralSettings() {
      const allOperatorGeneralSettings = this.operatorGeneralSettings
      if (this.general.is_reservation_required === false) {
        const removeSettingsKey = ['release_spot_reservation_time_in_min']
        const filteredSettings = allOperatorGeneralSettings.filter(
          (item) => !removeSettingsKey.includes(item.variable)
        )
        return filteredSettings
      } else {
        return this.operatorGeneralSettings
      }
    },
  },
  watch: {
    currentSettingsGroup: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val === 'operatorGeneral') {
          this.operatorWageSettings = this.operatorWageSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))
        }
        if (val === 'operatorWage') {
          this.operatorGeneralSettings = this.operatorGeneralSettings.map(
            (item) => ({
              ...item,
              isEdit: false,
            })
          )
        }
      },
    },
  },
  async created() {
    const reqCharging = this.$http.get(
      OperatorSettingsConfig.api.charging.index(this.orgId)
    )
    const reqRebalancing = this.$http.get(
      OperatorSettingsConfig.api.rebalancing.index(this.orgId)
    )
    const reqGeneral = this.$http.get(
      OperatorSettingsConfig.api.general.index(this.orgId)
    )
    this.$http
      .all([reqCharging, reqRebalancing, reqGeneral])
      .then(
        this.$http.spread((...responses) => {
          console.log('settignsData', responses)
          this.general.drop_off_photo_required =
            responses[2].data.drop_off_photo_required
          this.general.is_reservation_required =
            responses[2].data.is_reservation_required
          this.general.check_inside_drop_off_area =
            responses[2].data.check_inside_drop_off_area
          this.general.release_spot_reservation_time_in_min =
            responses[2].data.release_spot_reservation_time_in_min

          this.wage.charging.rate = responses[0].data.rate
          this.wage.charging.point = responses[0].data.point

          this.wage.rebalancing.rate = responses[1].data.rate
          this.wage.rebalancing.point = responses[1].data.point
          this.isLoaded = true
        })
      )
      .catch((err) => {
        console.log('settignsErr', err)
      })
  },
  mounted() {
    EventBus.$on('open-edit', (id) => {
      const idPrefix = id.split('_')[0]
      if (idPrefix === 'operatorGeneral') {
        this.operatorGeneralSettings = this.operatorGeneralSettings.map(
          (item, itemIndex) => {
            if (id === `operatorGeneral_${itemIndex}`) {
              return {
                ...item,
                isEdit: true,
              }
            } else {
              return {
                ...item,
                isEdit: false,
              }
            }
          }
        )
        this.currentSettingsGroup = 'operatorGeneral'
      }
      if (idPrefix === 'operatorWage') {
        this.operatorWageSettings = this.operatorWageSettings.map(
          (item, itemIndex) => {
            if (id === `operatorWage_${itemIndex}`) {
              return {
                ...item,
                isEdit: true,
              }
            } else {
              return {
                ...item,
                isEdit: false,
              }
            }
          }
        )
        this.currentSettingsGroup = 'operatorWage'
      }
    })
  },
  methods: {
    async onSave(group, key, val) {
      let toastTitle, toastText

      if (key === 'drop_off_photo_required') {
        toastTitle = `Drop-off Photo Required`
        toastText = `Drop-off Photo Required has been updated`
      }
      if (key === 'check_inside_drop_off_area') {
        toastTitle = `Check Inside Drop-off Area`
        toastText = `Check Inside Drop-off Area has been updated`
      }
      if (key === 'is_reservation_required') {
        toastTitle = `Reservation Required`
        toastText = `Reservation Required has been updated`
      }
      if (key === 'release_spot_reservation_time_in_min') {
        toastTitle = `Release Spot Reservation Timeout (Minutes)`
        toastText = `Release Spot Reservation Timeout (Minutes) has been updated`
      }
      if (group === 'charging' && key === 'rate') {
        toastTitle = `Earning Rate (Charging)`
        toastText = `Earning Rate (Charging) has been updated`
      }
      if (group === 'charging' && key === 'point') {
        toastTitle = `Point (Charging)`
        toastText = `Point (Charging) has been updated`
      }
      if (group === 'rebalancing' && key === 'rate') {
        toastTitle = `Earning Rate (Rebalance)`
        toastText = `Earning Rate (Rebalance) has been updated`
      }
      if (group === 'rebalancing' && key === 'point') {
        toastTitle = `Point (Rebalance)`
        toastText = `Point (Rebalance) has been updated`
      }

      let data = new FormData()
      data.append(key, val)
      let reqLink = null
      if (group === 'general') {
        reqLink = OperatorSettingsConfig.api.general.index(this.orgId)
      }
      if (group === 'charging') {
        reqLink = OperatorSettingsConfig.api.charging.index(this.orgId)
      }
      if (group === 'rebalancing') {
        reqLink = OperatorSettingsConfig.api.rebalancing.index(this.orgId)
      }
      await this.$http
        .patch(reqLink, data)
        .then(() => {
          this.operatorGeneralSettings = this.operatorGeneralSettings.map(
            (item) => ({
              ...item,
              isEdit: false,
            })
          )
          this.operatorWageSettings = this.operatorWageSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))

          this.$notify(
            {
              group: 'generic',
              type: 'success',
              title: toastTitle,
              text: toastText,
            },
            3000
          )
          this.counter++
        })
        .catch((err) => {
          console.log('onSaveError', err)
          this.$notify(
            {
              group: 'generic',
              type: 'error',
              title: toastTitle,
              text: 'Failed to update settings',
            },
            3000
          )
        })
    },
  },
}
</script>

<style lang="scss">
.pencil-icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.title-description {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #63636c !important;
}
</style>
